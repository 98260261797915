<template>
  <v-main>
    <span class="bg"></span>
    <!--  <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"> -->
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" lg="3">
          <v-scale-transition>
            <v-card class="elevation-12" v-if="!isLoading">
              <v-toolbar color="indigo" dark flat>
                <v-toolbar-title>Seguros Atocha - Acceso</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user"
                    label="Usuario"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                  />

                  <v-text-field
                    v-model="pass"
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="indigo" dark @click="loginUser">Acceso</v-btn>
              </v-card-actions>
            </v-card>
          </v-scale-transition>
        </v-col>
      </v-row>

      <v-snackbar
        :top="true"
        :right="true"
        :color="snackbar.color"
        v-model="snackbar.mostrar"
        :timeout="4000"
      >
        {{ snackbar.texto }}
        <!--  <v-btn outlined text @click="snackbar.mostrar = false">
          Cerrar
        </v-btn> -->
      </v-snackbar>
    </v-container>
    <!--  </v-parallax> -->
    <!-- snackbar general -->
    <!--     <v-snackbar
      class="snackPos"
      :top="true"
      :right="true"
      :color="snackbar.color"
      v-model="snackbar.mostrar"
      :timeout="2000"
      centered
    >
      {{ snackbar.texto }}
    
    </v-snackbar> -->
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import DisableAutocomplete from "@aacassandra/vue-disable-autocomplete";
Vue.use(DisableAutocomplete);
export default {
  name: "App",
  data: () => ({
    isLoading: true,
    user: null,
    pass: null,
    /*     snackbarLocal: {
      mostrar: false,
      texto: "",
      color: "primary",
      top: true,
      right: true
    }, */
    snackbar: {
      mostrar: false,
      texto: "",
      color: "primary",
      top: true,
      right: true
    }
  }),
  methods: {
    ...mapActions("user", ["setLoginUser"]),
    loginUser() {
      const username = this.user || null;
      const password = this.pass || null;
      if (!username || !password) {
        this.snackbar.texto = "No se han informado los campos correctamente";
        this.snackbar.color = "error";
        this.snackbar.mostrar = true;
        return;
      }
      this.setLoginUser({ username, password }).then(res => {
        if (res) {
          this.$router.push("/fichas");
        } else {
          this.snackbar.texto = "Credenciales erróneas";
          this.snackbar.color = "error";
          this.snackbar.mostrar = true;
        }
      });
      /*       .catch(err => {
          this.snackbar.texto = "Credenciales erróneas";
          this.snackbar.color = "error";
          this.snackbar.mostrar = true;
        }); */
    },
    logearse() {
      const datos = { user: this.user, passw: this.pass };
      if (
        this.user === null ||
        this.user === "" ||
        this.pass === null ||
        this.pass === ""
      ) {
        this.snackbar.texto = "No se han informado los campos correctamente";
        this.snackbar.color = "error";
        this.snackbar.mostrar = true;
        return;
      }

      this.setLoginUser(datos).then(res => {
        if (res) {
          setTimeout(() => {
            this.$router.push({ name: "fichas" });
          }, 800);
        } else {
          this.snackbar.texto = "Los datos de acceso no son correctos";
          this.snackbar.color = "error";
          this.snackbar.mostrar = true;
        }
      });
    }
  },
  computed: {
    ...mapGetters("comun", ["getterMainLoader", "getterSnackbar"])
  },
  watch: {
    getterSnackbar: {
      immediate: true,
      deep: true,
      handler() {
        this.snackbar = this.getterSnackbar;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 800);
  }
};
</script>
<style scoped></style>
